<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol col="12" xl="12">
            <CCard>
              <div style="display: flex; justify-content:space-between; align-items:center;">
              <CCardHeader>
                Usuarios (Usuarios Aplicación)
                <CButton
                  color="primary"
                  size="sm"
                  class="m-1"
                  @click="openModalCreateUser"
                >
                  Crear Usuario
                </CButton>
              </CCardHeader>
                <p style="margin-right:12px;">Total de Usuarios: {{this.total}}</p>
              </div>
              <CCardBody>
                <CDataTable
                  v-show="!loading"
                  hover
                  striped
                  :items="items"
                  :fields="fields"
                  :items-per-page="pagination.perPage"
                  :pagination="pagination"
                  index-column
                  clickable-rows
                >
                <template #item="data"> 
                  <td>{{data.created_at | formatDate}}</td> 
                </template>
<!--                   <template #telefono="data">
                    <td>{{data.item.info.telefono}}</td>
                  </template>
                  <template #direccion="data">
                    <td>{{data.item.info.direccion}}</td>
                  </template>
                  <template #cuadrante="data">
                    <td>{{getCuadranteName(data.item.info.cuadrante_id)}}</td>
                  </template> -->
                  <template #role="data">
                    <td>{{data.role}}</td>
 <!--                    <div>
                      <CButton
                        v-c-tooltip="'Editar datos de usuario'"
                        color="warning"
                        size="sm"
                        class="m-1"
                        @click="updateUser(data.item, $event)"
                        v-if="checkPermissions(['edit_user_app'])"
                      >
                        <font-awesome-icon icon="user-edit" />
                      </CButton>
                      <CButton
                        v-c-tooltip="'Eliminar usuario'"
                        color="danger"
                        size="sm"
                        class="m-1"
                        @click="deleteUser(data.item.id, $event)"
                        v-if="checkPermissions(['edit_user_app'])"
                      >
                        <font-awesome-icon icon="trash-alt" />
                      </CButton>
                    </div> -->
                  </template>
                </CDataTable>
              <div style="width: 200px; margin: 0 auto;">
              <CPagination
              :active-page.sync="currentPage"
              :pages="Math.ceil(total / pagination.perPage)"
              align="center"
            />
            </div>
                <div 
                  v-if="loading"
                  class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
                  <CSpinner
                    style="width:4rem;height:4rem;"
                    color="danger"
                  />
                  <p class="text-secondary my-2">Cargando datos ...</p>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
<!--           <CCol col="3" xl="3">
            <CCard>
              <CCardHeader>
                Estadísticas
              </CCardHeader>
              <CCardBody>
                <CChartPie
                  v-if="estadisticas && estadisticas.gender && estadisticas.gender.values.length > 0"
                  :datasets="[{
                    backgroundColor: [
                      '#41B883',
                      '#d0282e',
                      '#00D8FF',
                      '#DD1B16'
                    ],
                    data: estadisticas.gender.values
                  }]"
                  :labels="estadisticas.gender.keys"
                />
                <CChartLine
                  class="mt-5"
                  v-if="estadisticas && estadisticas.age && estadisticas.age.values.length > 0"
                  :datasets="[{
                    label: 'Edad',
                    backgroundColor: ['#d0282e'],
                    data: estadisticas.age.values
                  }]"
                  :labels="estadisticas.age.keys"
                  :options="{ 
                    scales: {
                      yAxes: [{
                        ticks: {
                          stepSize: 1
                        }
                      }]
                    }
                  }"
                />
              </CCardBody>
            </CCard>
          </CCol> -->
        </CRow>
      </transition>
    </CCol>
    <CreateUser :modalCreateUser="modalCreateUser" :closeModal="() => closeModal()"/>
  </CRow>
</template>

<script>
import CreateUser from './CreateUser'
import { getUsers, deleteUser } from '../../api/users'
import checkPermissions from '../../helpers/userPermissions'
import { CChartPie, CChartLine } from '@coreui/vue-chartjs'
import moment from "moment";


import * as infermedica from '../../api/infermedica'

export default {
  name: 'Users',
  components: {CreateUser, CChartPie, CChartLine},
  data: () => {
    return {
      items: [],
      estadisticas: null,
      fields: [
/*         { key: 'id', label: '#' }, */
/*         { key: 'name', label: 'Nombre' }, */
        { key: 'email', label: 'Correo Electrónico' },
/*         { key: 'telefono', label: 'Teléfono' }, */
/*         { key: 'direccion', label: 'Dirección' }, */
        { key: 'created_at', label: 'Creado' },
        { key: 'role', label: 'Rol' }
      ],
      currentPage: 1,
      total: null,
      pagination: {
        start: 1,
        perPage: 10,
      },
      updatingUser: null,
      loading: true,
      modalCreateUser: false
    }
  },
  mounted(){
    this.getUsers()
  },
    watch: {
    currentPage: function (start) {
      this.pagination = {
        start,
        perPage: this.pagination.perPage,
      };
      this.getUsers()
    },
  },
  methods: {
    checkPermissions,
    goToFicha(userID){      
      this.$router.push({ name: 'FichaPaciente', params: {id: userID} })
    },
    closeModal(){
      this.modalCreateUser = false
      this.getUsers()
    },
    openModalCreateUser() {
      this.modalCreateUser = true
    },
    updateUser(user, e){
      e.stopPropagation()
      this.updatingUser = user
      this.modalCreateUser = true
    },
    deleteUser(id, e){
      e.stopPropagation()
      const data = {id}
      const self = this
      deleteUser(data)
        .then(function(resp){
          console.log(resp)
          self.getUsers()
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getUsers(){
      const data = {
        limit: this.pagination.perPage,
        start: this.pagination.start
      }
      const self = this
      this.loading = true
      getUsers(data)
        .then(function(resp){
          const arr = [...resp.users]
          arr.forEach( user => user.created_at = moment(user.created_at).format("DD-MM-YYYY"))
          self.items = arr

          
          self.total = resp.users_count
          console.log(resp)
          self.estadisticas = resp.user_stadistics
          self.loading = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    }
  },
    filters: {
      formatDate: function (date) {
        return moment(date).format("DD-MM-YYYY")
      },
}
}
</script>
